/* @flow */

import * as React from 'react'
import { ErrorTemplate } from '../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Error(props: Props) {
  const { location } = props
  return (
    <ErrorTemplate
      url={location.href}
      code={404}
      name="Wino"
      title="Ooops ! Cette page ne semble pas disponible actuellement!"
      description="Retenter votre chance ou retourner sur l’accueil du site en cliquant sur le bouton ci-dessous"
    />
  )
}
